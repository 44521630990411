export const TRANSPOSE_BLOCK = 'btas-transpose-block';
export const ADVANCED_TRANSPOSE_BLOCK = 'btas-advanced-transpose-block';
export const JOIN_BLOCK = 'btas-join-block';
export const INPUT_OUTPUT_TOGGLE = 'btas-input-output-toggle';
export const STATE_TAX_RATES = 'btas-state-tax-rates';
export const FEEDBACK_FORM = 'btas-feedback-form';
export const DELETE_SOURCE_FILE = 'btas-delete-source-file';
export const SEND_TO_TAX_PROVISION = 'btas-send-to-tax-provision';
export const EXPORT_TO_FIX_ASSETS = 'btas-export-to-fixed-assets';
export const WKP_TEMPLATES_LIST = 'btas-dynamic-wkp-templates';
export const IMPROVE_DATAFLOW_PREVIEW = 'btas-wkp-improve-dataflow-preview';
export const WKP_LANDING_ON_DATAFLOW = 'btas-wkp-landing-on-dataflow';
export const DF_HIDE_RECENT_ACTIVITY_PANEL = 'btas-wkp-hide-recent-activity-panel';
export const WKP_CREATE_LINKS = 'btas-wkp-create-links';
export const DF_INPUT_FA_OPTION = 'btas-wkp-input-fix-asset-integration';
export const DF_INPUT_SFTP_OPTION = 'btas-wkp-sftp-integration';
export const DF_GLOBAL_TEMPLATES = 'btas-wkp-is-global-template';
export const DF_GLOBAL_TEMPLATE_MODAL = 'btas-wkp-global-template-modal';
export const FIND_AND_REPLACE_PROMPT = 'btas-wkp-dt-find-and-replace';
export const ROLLFORWARD_ENABLED = 'btas-wkp-rollforward-enabled';
export const ENABLE_FIELD_NAME_HASHING = 'btas-wkp-enable-field-name-hashing';
export const SELECT_BLOCK_REWRITE = 'btas-wkp-select-block-rewrite';
export const REORDER_BLOCK = 'btas-wkp-reorder-block';
export const RESEARCH_AI = 'btas-wkp-research-ai';
export const MULTIMERGE_BLOCK = 'btas-wkp-multimerge-block';
export const CELL_REVIEW_HOME_PAGE = 'btas-wkp-cell-review-home-page';
export const WKP_AI_FORMULA_GENERATION = 'btas-workpapers-ai-formula-generation';
export const WKP_GLOBAL_TEMPLATES_LIST = 'btas-wkp-global-templates-list';
export const RECYCLE_BIN_ENABLED = 'btas-wkp-recycle-bin';
export const SJS_API = 'btas-wkp-sjs-command-processing';
export const RECOVER_WORKPAPER = 'btas-wkp-recover-workpaper';
export const WKP_USER_PERMISSIONS = 'btas-wkp-user-permissions';
export const WKP_DATAFLOW_SIDEBAR = 'btas-wkp-dataflow-sidebar';
export const WKP_GLOBAL_TEMPLATES_FILTER = 'btas-wkp-global-templates-filter';
export const WKP_INPUT_FILE_IMPORT = 'btas-wkp-input-file-import';
export const WKP_INGEST_REWRITE = 'btas-wkp-ingest-rewrite';
export const WKP_TP_CLASSIC_S3_ORGANIZATION = 'btas-wkp-tp-classic-s3-organization';
export const WKP_CONFIG_PANEL_PAGINATION = 'btas-workpapers-config-panel-pagination';
export const SEND_TO_SFTP_SPREADSHEET = 'btas-wkp-send-to-sftp-spreadsheet';
export const WKP_DF_PREVIEW_PAGINATION = 'btas-wkp-preview-pagination';
export const WKP_REFRESH_TOKEN_REWRITE_UI = 'btas-wkp-refresh-token-rewrite-ui';
export const WKP_INPUT_SINGLE_FILE_IMPORT = 'btas-wkp-input-single-file-import';
export const DATA_LINKS = 'btas-wkp-data-links';
export const WKP_WORKFLOW_SUPPORT_DOWNLOAD = 'btas-wkp-audit-report-download';
export const WKP_STATE_TAX_RATES_API_V2 = 'btas-wkp-state-tax-rate-api-v2';
export const WKP_CM_INTEGRATION = 'btas-wkp-cm-integration';
