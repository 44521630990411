/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUserProfile } from '../../../../hooks/useUserProfile';
import { BTIcon } from '@btas/jasper';
import { clientAccessManagerUrl } from '../../../../configs/params';
import './styles.scss';

const ClientPickerMenu = () => {
  const { clients, selectedClient, setSelectedClient, isAdmin } = useUserProfile();
  const userIsAdmin = isAdmin();

  const handleClientClick = client => {
    setSelectedClient(client);
  };

  return (
    <li className="dropdown clients-dropdown">
      <a
        className="dropdown-toggle client-picker-header"
        data-testid="client-picker"
        data-toggle="dropdown"
        href="#"
        id="client-picker"
        title="ACME"
        type="button"
      >
        <div>
          <img alt="Client Picker" src="/assets/locked_folder.svg" />
          <span data-testid="selected-client-name">{selectedClient?.name}</span>
          <img alt="arrow down icon" className="caret-down" src="/assets/caret_down.svg" />
        </div>
      </a>
      <ul aria-labelledby="client-picker" className="dropdown-menu dropdown-menu-clients" role="menu">
        <div className="clients-container">
          <li aria-level="2" className="dropdown-header clients-header" role="heading">
            SELECT CLIENT
          </li>
          {clients.map((client, index) => (
            <a
              key={client.id}
              className={`client-item app-link ${selectedClient?.id === client.id ? 'active' : ''}`}
              rel="noreferrer"
              role="menuitem"
              onClick={() => handleClientClick(client)}
            >
              <span className="name-menu-apps" onClick={() => handleClientClick(client)}>
                {client.name}
              </span>
            </a>
          ))}
        </div>
        {userIsAdmin && (
          <>
            <div className="clients-divider"></div>
            <a className="clients-manager-link" href={clientAccessManagerUrl} rel="noreferrer" target="_blank">
              <div className="clients-manager">
                <BTIcon icon="gear" />
                <span>Manage Clients...</span>
              </div>
            </a>
          </>
        )}
      </ul>
    </li>
  );
};

export default ClientPickerMenu;
