import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import GC from '../../../SpreadSheets';
import MessageType from '../../_shared/PubSub/pubSubMessageType';
import usePubSub from '../EditorPage/PubSub/usePubSub';
import useResize from './Spreadsheet/_spreadsheets/useResize';
import { setDataToLocalStorage, getDataFromLocalStorage, removeDataFromLocalStorage } from '../../_shared/storage';
import { generateStaticVersion, getLock, getStaticVersionStatus, getWorkbookMetadata, getWorkbookState } from './apis';
import EditorContext from './EditorContext';
import { registerGlobalCustomFunctions } from './Spreadsheet/_spreadsheets';
import {
  overrideRibbonFilterCommand,
  setGoToSourceCommandsBehavior,
} from './Spreadsheet/_spreadsheets/commandsBehavior';
import { prepareCommand } from './useWorkpaper/processCommand';
import WorkbookManager from '../../_shared/WorkbookManager';
import { CopyExecute } from './Spreadsheet/_spreadsheets/sheetHelpers';
import useLockWorkpaper from './useWorkpaper/useLockWorkpaper';
import { overrideSheetDeleteEvent } from './useWorkpaper/sheetDeleteOverride';
import { getCellTag, isEmptyObject, setCellTag } from './DataReference/dataReferenceHelper';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { DATA_LINKS, SJS_API } from '../../../constants/featureFlags';
import { decodeWorksheetUrl } from './worksheetUrlFormatter';
import { useKeyData } from './Spreadsheet/_spreadsheets/useKeyData';
import { getWorkflowStatus } from './Spreadsheet/SideBar/WorkflowPanel/Status/api';
import { cellFormulaCount, customFunctionNames, restoreCell } from './Spreadsheet/_spreadsheets/formulas';
import CustomLogger from '../../_shared/Logger/CustomLogger';
import { applyTimestamp, getSheetOptions, preprocessCommand, toJSON } from '../../../sjs-cmd-process';
import { UserPermissionsContext } from '../../_shared/UserPermissionsContext';
import { processPendingCommandsWithSJS } from './EditorContext/useCommandsQueue/apis';
import { getUserId } from '../../_shared/auth';
import useSyncCommands from './useWorkpaper/useSyncCommands';
import { useDataLink } from './useDataLink';
import { useEditorToggle } from './useEditorToggle';

export default function useWorkpaper({ id, versionId, navigator }) {
  const {
    spreadRef,
    isLocked,
    setIsLoading,
    setIsLoadingInBackground,
    setIsLocked,
    setIsSignOffPermission,
    setCommandsVisibleContext,
    addStatusBar,
    getStatusBarItem,
    statusBarAddedRef,
    enqueueCommands,
    enqueueDataReference,
    setWorkpaperId,
    setWorkpaperStatus,
    loadWorkbookDataReferences,
    trackDataReferenceCellPositions,
    clearReferenceCell,
    trackPastedReference,
    renderCustomFormulaValues,
    getCellReferenceTag,
    enqueueDataReferenceReCalc,
    allCommandsProcessedAsync,
    isDragFillAction,
    isCopyPasteAction,
    referenceExistInWorksheet,
    referenceExistInTargetCell,
    referenceIsEnqueuedForRecalc,
    isFormulaMatch,
    syncDatareferences,
    stateTaxJurisdictions,
    dataReferenceQueue,
    dataReferenceWorkpaperId,
    cellChangedData,
    dataReferenceWorkpaperVersionId,
    lastCommandAppliedTime,
  } = useContext(EditorContext);
  const versionIdRef = useRef(versionId);
  const [isWorkpaperLoaded, setIsWorkpaperLoaded] = useState(false);
  const [lockInfo, setLockInfo] = useState();
  const [loadWorkbookFailed, setLoadWorkbookFailed] = useState(false);
  const [loadWorkbookNotFound, setLoadWorkbookNotFound] = useState(false);
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const { getAllKeyValues, createKeyValue } = useKeyData({ workpaperId: id });
  const workbookManager = WorkbookManager.getInstance();
  const keyValuePairs = useRef([]);

  const { publish } = usePubSub();
  const { setResized } = useResize({ id });
  const history = useHistory();
  const { userPermissions } = useContext(UserPermissionsContext);
  const { setupSpreadListeners } = useDataLink({ workpaperId: id });
  const { disableEditor } = useEditorToggle();

  const verifyStaticVersion = async id => {
    let isInProgress;
    if (isFeatureFlagEnabled(SJS_API)) {
      const { state } = await getWorkbookState(id);
      isInProgress = state === 'inProgress';
    } else {
      const staticStatus = await getStaticVersionStatus(id);
      if (!staticStatus?.isStatic && !staticStatus?.isInProgress && !staticStatus?.isCellReviewMigrationInProgress) {
        const forceGeneration =
          !staticStatus?.isInProgress &&
          !staticStatus?.staticFailure &&
          !staticStatus?.isCellReviewMigrationInProgress &&
          !staticStatus?.isExcelGenerated &&
          !staticStatus?.isSdcReconnected;
        await generateStaticVersion(id, forceGeneration);
      }
      isInProgress = staticStatus?.isInProgress;
    }

    if (isInProgress) {
      window.location.reload();
    }
  };

  const { initLockRenew, lockCleanup, isLockInitialized } = useLockWorkpaper({
    id,
  });
  const { initSyncRenew, syncCleanup, isSyncInitialized } = useSyncCommands(id);

  let cmdsNeeded = {
    'gc.spread.contextMenu.hideSheet': true,
    'gc.spread.contextMenu.unhideSheet': true,
    'gc.spread.contextMenu.deleteSheet': true,
    moveSheet: true,
    sheetTabLeft: true,
    sheetTabRight: true,
    sheetTabTop: true,
    sheetTabBottom: true,
  };

  const storeWorkbookActiveSheetIndex = (newValue, userId, workpaperId, index) => {
    if (!newValue) return;
    setDataToLocalStorage(`${userId}-selectedSheetIndex-${workpaperId}`, index);
  };

  const setWorkbookActiveSheetIndex = (workbook, userId, workpaperId) => {
    const spread = spreadRef.current;
    const sheetsCount = Object.keys(workbook.sheets).length;
    const wkpOpenedPreviously = 'wkpOpenedPreviously';
    const wasOpenedBefore = keyValuePairs.current.find(pair => pair.key === wkpOpenedPreviously)?.value;
    if (wasOpenedBefore !== 'true' && spread.sheets.length) {
      const firstVisibleSheet = spread.sheets.find(s => s.visible() === GC.Spread.Sheets.SheetTabVisible.visible);
      const sheetIndex = spread.getSheetIndex(firstVisibleSheet.name());
      spread.setActiveSheetIndex(sheetIndex);
      firstVisibleSheet.setActiveCell(0, 0);
    } else {
      const index = getDataFromLocalStorage(`${userId}-selectedSheetIndex-${workpaperId}`);
      if (index && Number(index) < sheetsCount) {
        spread.setActiveSheetIndex(Number(index));
      } else {
        spread.setActiveSheetIndex(Number(sheetsCount - 1));
      }
    }
  };

  const moveCellTag = a => {
    const sheet = a.sheet;
    for (let r = 0; r < a.rowCount; r++) {
      for (let c = 0; c < a.colCount; c++) {
        const sourceRow = a.fromRow + r;
        const sourceCol = a.fromCol + c;
        const targetRow = a.toRow + r;
        const targetCol = a.toCol + c;
        const sourceTag = getCellTag(sheet, sourceRow, sourceCol);
        if (sourceTag) {
          setCellTag(sheet, targetRow, targetCol, sourceTag);
        }
      }
    }
  };

  const displayCircularReferenceAlert = a => {
    const spread = spreadRef.current;
    const message =
      'There are one or more circular references where a formula refers to its own cell either directly or indirectly. This will cause them to calculate incorrectly. Try removing or changing these references, or moving the formulas to different cells.';
    if (a?.isCircularReference && !spread.options.iterativeCalculation) {
      GC.Spread.Sheets.Designer.showMessageBox(message, 'Warning', GC.Spread.Sheets.Designer.MessageBoxIcon.warning);
    } else if (a.cellRange) {
      const { row, col, rowCount, colCount } = a.cellRange;
      var circularReferences = spread.getCircularReference();
      if (circularReferences.length) {
        for (let r = 0; r < rowCount; r++) {
          for (let c = 0; c < colCount; c++) {
            const selectedRow = row + r;
            const selectedCol = col + c;
            const referenceExist = circularReferences.filter(
              x => x.row === selectedRow && x.col === selectedCol && x.sheetName === a.sheetName
            );
            if (referenceExist.length) {
              GC.Spread.Sheets.Designer.showMessageBox(
                message,
                'Warning',
                GC.Spread.Sheets.Designer.MessageBoxIcon.warning
              );
              break;
            }
          }
        }
      }
    }
  };

  const onWorkpaperLoaded = (readOnly, isGlobalTemplate) => {
    statusBarAddedRef.current = false;
    const ss = spreadRef.current;
    ss.options.allowDynamicArray = true;
    ss.options.allowCopyPasteExcelStyle = true;
    ss.options.allowAutoExtendFilterRange = true;
    ss.options.iterativeCalculation = false;
    ss.options.allowAutoExtendFilterRange = true;
    ss.options.allowDragHeaderToMove = GC.Spread.Sheets.AllowDragHeaderToMove.both;
    publish({ message: MessageType.LoadWorkbookDataReferences, body: id, callback: loadWorkbookDataReferences });
    syncDatareferences.trackDataReferencesAction.current = trackDataReferenceCellPositions;
    syncDatareferences.loadDataReferencesAction.current = loadWorkbookDataReferences;
    if ((id && !versionId) || !readOnly) {
      /* START GC code: will get optimized once all commands are fully supported */
      const { Sheets: spreadNS } = GC.Spread;
      const { extend } = spreadNS.GC$;

      if (isFeatureFlagEnabled(DATA_LINKS)) setupSpreadListeners();

      ss.bind(spreadNS.Events.ActiveSheetChanged, function () {
        setResized(spreadRef.current, lastCommandAppliedTime);
        ss.setActiveSheetIndex(ss.getActiveSheetIndex());
      });
      ss.bind(spreadNS.Events.ActiveSheetChanging, function (e, a) {
        if (a.newSheet) {
          const workpaperData = JSON.parse(getDataFromLocalStorage(id) || '{}');
          workpaperData['copyWorksheet'] = false;
          setDataToLocalStorage(id, JSON.stringify(workpaperData));
        }
      });

      if (isFeatureFlagEnabled(SJS_API)) {
        ss.bind(GC.Spread.Sheets.Events.SheetChanged, (e, a) => {
          const isIgnoreInsert = getDataFromLocalStorage(`ignore-insert-sheet-${id}`);
          if (a.propertyName === 'insertSheet' && !isIgnoreInsert) {
            if (a.sheetIndex === ss.sheets.length - 1) {
              // clicked + sheet button
              let sheet = ss.getSheetFromName(a.sheetName);
              let command = {
                cmd: 'insertSheet',
                sheetIndex: a.sheetIndex,
                sheetName: a.sheetName,
                data: getSheetOptions(sheet),
              };
              enqueueCommands([{ commandText: JSON.stringify(applyTimestamp(preprocessCommand(ss, command))) }]);
            }
          } else if (a.propertyName === 'isSelected') {
            storeWorkbookActiveSheetIndex(a.newValue, getUserId(), id, a.sheetIndex);
          }
        });
      } else {
        ss.bind(spreadNS.Events.SheetChanged, (e, a) => {
          let command = null;
          switch (a.propertyName) {
            case 'insertSheet':
              command = extend({}, a);
              command.cmd = 'insertSheet';
              command.data = ss.sheets[a.sheetIndex].toJSON();
              break;
            case 'deleteSheet':
              command = extend({}, a);
              command.cmd = 'deleteSheet';
              break;
            case 'isSelected':
              storeWorkbookActiveSheetIndex(a.newValue, getUserId(), id, a.sheetIndex);
              break;
            default:
              break;
          }
          if (command) {
            if (command.cmd.indexOf('deleteSheet') >= 0 && !command.deletedSheets) {
              return;
            }
            command = prepareCommand(GC, ss, command, a.actionType);
            enqueueCommands([{ commandText: JSON.stringify(command) }]);
          }
        });
      }

      ss.bind(GC.Spread.Sheets.Events.DragDropBlock, (e, a) => {
        moveCellTag(a);
      });

      ss.bind(GC.Spread.Sheets.Events.ValueChanged, function (e, a) {
        publish({ body: a, message: MessageType.ClearReferenceCell, callback: clearReferenceCell });
      });

      ss.bind(GC.Spread.Sheets.Events.ClipboardPasted, function (e, a) {
        publish({ body: a, message: MessageType.CutPasteReference, callback: trackPastedReference });
        displayCircularReferenceAlert(a);
      });

      ss.bind(GC.Spread.Sheets.Events.EditEnding, function (e, a) {
        cellChangedData.current = a;
      });

      ss.bind(GC.Spread.Sheets.Events.UserFormulaEntered, function (e, a) {
        displayCircularReferenceAlert(a);
      });

      ss.bind(GC.Spread.Sheets.Events.ClipboardPasting, function (e, a) {
        if (a.isCutting === false) {
          if (a.fromSheet && a.fromSheet.name() === a.sheetName) {
            const { row, col } = a.fromRange;
            isCopyPasteAction.current = { isActive: true, sameSheet: true, entireSheet: row && col === -1 };
          } else {
            isCopyPasteAction.current = { isActive: true, sameSheet: false };
          }
        }
      });

      ss.bind(GC.Spread.Sheets.Events.DragFillBlock, function (e, a) {
        const spreadsheet = a.sheet;
        const selectedRanges = spreadsheet.getSelections();
        let { row, col, rowCount, colCount } = selectedRanges[0];
        if (
          isDragFillAction.current.direction === GC.Spread.Sheets.Fill.FillDirection.up ||
          isDragFillAction.current.direction === GC.Spread.Sheets.Fill.FillDirection.left
        ) {
          row = row + rowCount - 1;
          col = col + colCount - 1;
        }
        for (let r = 0; r < rowCount; r++) {
          for (let c = 0; c < colCount; c++) {
            const sourceRow = row + r;
            const sourceCol = col + c;
            const sourceCellFormula = spreadsheet.getFormula(sourceRow, sourceCol);
            if (sourceCellFormula && customFunctionNames.some(frml => sourceCellFormula.includes(frml))) {
              isDragFillAction.current = { isActive: true, direction: a.fillDirection };
              break;
            }
          }
        }
      });

      ss.bind(GC.Spread.Sheets.Events.EnterCell, function (e, a) {
        decodeWorksheetUrl(a);
      });

      const wkpOpenedPreviously = 'wkpOpenedPreviously';
      const wasOpenedBefore = keyValuePairs.current.find(pair => pair.key === wkpOpenedPreviously)?.value;
      if (wasOpenedBefore !== 'true') {
        createKeyValue({ workpaperId: id, keyData: { key: wkpOpenedPreviously, value: 'true', forceSave: true } });
      }

      const commandManager = ss.commandManager();

      commandManager.addListener('remoteCommands', arg => {
        const sjsEnabled = isFeatureFlagEnabled(SJS_API);
        let command = arg.command;
        if (command.cmd && command.cmd.length > 0) {
          setTimeout(() => {
            const canUndo = ss.commandManager()[command.cmd].canUndo();

            if (!sjsEnabled && !cmdsNeeded[command.cmd] && !canUndo && command.cmd.indexOf('Designer.') !== 0) {
              return;
            }

            if (!sjsEnabled && command.cmd === 'Designer.formatPainterCancel') {
              return;
            }

            if (
              sjsEnabled &&
              (command.cmd === 'OpenPivotFilterDialogCmd' ||
                command.cmd === 'createFilterSecondaryMenu' ||
                command.cmd === 'createDialogForLabelFilter' ||
                command.cmd === 'fieldSet')
            ) {
              return;
            }
            if (
              sjsEnabled &&
              (command.cmd === 'CreatePivotFilterDialog' ||
                command.cmd === 'CreatePivotViewsListDialog' ||
                command.cmd === 'openValueFieldSettingDialogCmd' ||
                command.cmd === 'openTextEditor')
            ) {
              return;
            }

            if (
              sjsEnabled &&
              (command.cmd === 'showAllSheetsDialog' ||
                command.cmd === 'gc.spread.contextMenu.allSheets' ||
                command.cmd === 'shapeUnGroup' ||
                command.cmd === 'shapeGroup')
            ) {
              return;
            }
            if (
              sjsEnabled &&
              (command.cmd === 'contextMenuUnhideSheet' ||
                command.cmd === 'bringShapeToFront' ||
                command.cmd === 'bringShapeForward' ||
                command.cmd === 'sendShapeToBack' ||
                command.cmd === 'sendShapeBackward')
            ) {
              return;
            }
            if (
              sjsEnabled &&
              (command.cmd === 'copy' ||
                command.cmd === 'cut' ||
                command.cmd === 'paste' ||
                command.cmd === 'Designer.copy' ||
                command.cmd === 'Designer.cut' ||
                command.cmd === 'Designer.paste' ||
                command.cmd === 'wkp.contextMenuMarkCellReviewed' ||
                command.cmd === 'wkp.contextMenuUnmarkCellReviewed')
            ) {
              return;
            }

            if (
              sjsEnabled &&
              (command.cmd === 'gc.spread.contextMenu.clearContents' ||
                command.cmd === 'clear' ||
                command.cmd === 'cellTag' ||
                command.cmd === 'wkp.copySheet')
            ) {
              return;
            }
            if (sjsEnabled && command.cmd === 'copySheet') {
              removeDataFromLocalStorage(`ignore-insert-sheet-${id}`);
            }

            if (
              command.cmd !== 'Designer.deleteSheet' &&
              command.cmd.indexOf('deleteSheet') >= 0 &&
              !command.deletedSheets
            ) {
              return;
            }

            if (command.cmd === 'clipboardPaste') {
              if (command.pasteSpecialOptions.operationOptions !== GC.Spread.Sheets.PasteOperationOptions.none) {
                const [{ row, col, rowCount, colCount }] = command.pastedRanges;
                const sheet = ss.getSheetFromName(command.sheetName);
                for (let r = row; r < row + rowCount; r++) {
                  for (let c = col; c < col + colCount; c++) {
                    const pastedFormula = sheet.getFormula(r, c);
                    if (pastedFormula && cellFormulaCount(pastedFormula)) {
                      ss.undoManager().undo();
                      return GC.Spread.Sheets.Designer.showMessageBox(
                        'You cannot combine multiple data connections or tax variable functions inside a single cell.',
                        'Warning',
                        GC.Spread.Sheets.Designer.MessageBoxIcon.warning
                      );
                    }
                  }
                }
              }
            }

            if (command.cmd === 'editCell') {
              const { row, col, sheetName } = command;
              const sheet = ss.getSheetFromName(command.sheetName);
              const { formulas } = command[`changes${sheetName}`];
              let formula;
              if (!sjsEnabled && formulas && formulas[row] && formulas[row][col]) formula = formulas[row][col].formula;
              if (sjsEnabled) formula = sheet.getFormula(row, col);

              if (formula && cellFormulaCount(formula)) {
                restoreCell(ss, command);
                // Removes the reference added from this prohibited formula entry
                dataReferenceQueue.current.pop();
                return GC.Spread.Sheets.Designer.showMessageBox(
                  'You cannot combine multiple data connections or tax variable functions inside a single cell.',
                  'Warning',
                  GC.Spread.Sheets.Designer.MessageBoxIcon.warning
                );
              }
            }

            if (command.cmd === 'Designer.sheetSettings') {
              delete command.options.sheetTabStateStylesAndTheme;
            }

            // server side measure text is not accurate, we change
            // the auto fit command to resize command.
            if (sjsEnabled) {
              if (command.cmd === 'autoFitColumn') {
                let change = command['changes' + command.sheetName];
                for (let i = 0; i < command.columns.length; i++) {
                  let col = command.columns[i];
                  if (change && change[i] && change[i].length > 0) {
                    col.size = change[i][2];
                  } else {
                    col.size = ss.getSheetFromName(command.sheetName).getColumnWidth(col.col);
                  }
                }
              } else if (command.cmd === 'autoFitRow') {
                let change = command['changes' + command.sheetName];
                for (let i = 0; i < command.rows.length; i++) {
                  let row = command.rows[i];
                  if (change && change[i] && change[i].length > 0) {
                    row.size = change[i][2];
                  } else {
                    row.size = ss.getSheetFromName(command.sheetName).getRowHeight(row.row);
                  }
                }
              }
            }

            try {
              if (sjsEnabled) {
                command = preprocessCommand(ss, command);
                if (arg.actionType !== 1) applyTimestamp(command);
                const commandToProcess = toJSON(GC, ss, command);
                if (arg.actionType !== 1 && !commandToProcess.timestamp && command.timestamp)
                  commandToProcess.timestamp = command.timestamp;
                if (arg.actionType === 1) delete commandToProcess.hM;
                if (commandToProcess.cmd === 'clipboardPaste') {
                  commandToProcess.fromSheet = arg.command?.fromSheet?.name();
                }
                command = commandToProcess;
              } else {
                command = prepareCommand(GC, ss, command, arg.actionType);
              }
            } catch (ex) {
              console.log(ex);
            }
            if (!sjsEnabled && command.cmd === 'Designer.unprotectSheet') {
              command.cmd = 'Designer.toggleSheetProtect';
            }
            command.actionType = arg.actionType;
            enqueueCommands([
              {
                commandText: JSON.stringify(command),
              },
            ]);
          }, 100);
        }
      });
    }

    if (!versionId && !readOnly && !isGlobalTemplate) {
      ss.options.saveChangesForSheet = true;
      CopyExecute(() => setConfirmModalVisible(true));
    }

    addStatusBar(ss);

    const overrideContextMenuItemsText = () => {
      const linkItem = ss.contextMenu.menuData.find(d => d.name === 'link');
      if (linkItem) {
        linkItem.text = 'Hyperlink...';
      }
    };

    const setCommandsBehavior = () => {
      const activeSheet = spreadRef.current.getActiveSheet();
      setGoToSourceCommandsBehavior(activeSheet, setCommandsVisibleContext);
    };

    const workpaperData = JSON.parse(getDataFromLocalStorage(id) || '{}');
    workpaperData.creatingWorkpaper = false;
    setDataToLocalStorage(id, JSON.stringify(workpaperData));
    return () => {
      if (versionId || readOnly || isGlobalTemplate || !userPermissions.includes('edit_workpaper')) {
        disableEditor();
      }
      overrideContextMenuItemsText();
      setCommandsBehavior();
    };
  };

  const load = useCallback(async () => {
    const operationStartTime = Date.now();
    lastCommandAppliedTime.current = new Date().toISOString();
    setIsLoading(true);
    setWorkpaperId(id);
    dataReferenceWorkpaperId.current = id;
    dataReferenceWorkpaperVersionId.current = versionId;
    versionIdRef.current = versionId;
    syncDatareferences.trackDataReferencesAction.current = trackDataReferenceCellPositions;
    try {
      workbookManager.resetStatusRetry();
      const { workbook, workbookLength } = await workbookManager.getWorkbookRequest(id, versionId);
      const [{ metadata, readOnly, isGlobalTemplate }, lockInfo, status] = await Promise.all([
        getWorkbookMetadata({ id, versionId }),
        getLock(id),
        getWorkflowStatus(id),
      ]);
      const signoff = readOnly ? false : userPermissions.includes('edit_workpaper_sign-offs');

      setWorkpaperStatus(status?.status);
      setIsLocked(!userPermissions.includes('edit_workpaper') || (readOnly && lockInfo?.userId !== null));
      setIsWorkpaperLoaded(true);
      setIsSignOffPermission(signoff);

      if (!versionId && !isLockInitialized()) {
        if (readOnly) {
          setLockInfo(lockInfo);
        } else {
          initLockRenew();
        }
      }

      if (!versionId && !isSyncInitialized()) {
        initSyncRenew(id);
      }

      const spread = spreadRef.current;
      overrideRibbonFilterCommand(spread);
      registerGlobalCustomFunctions(
        metadata.taxPeriod,
        id,
        enqueueCommands,
        spread,
        enqueueDataReference,
        renderCustomFormulaValues,
        getCellReferenceTag,
        enqueueDataReferenceReCalc,
        isDragFillAction,
        isCopyPasteAction,
        referenceExistInWorksheet,
        referenceExistInTargetCell,
        referenceIsEnqueuedForRecalc,
        isFormulaMatch,
        stateTaxJurisdictions,
        cellChangedData
      );
      setIsLoadingInBackground(true);
      const workpaperName = metadata.name;
      spread.open(
        workbook,
        () => {
          onWorkpaperLoaded(readOnly, isGlobalTemplate)();
          setIsLoadingInBackground(false);
          setIsLoading(false);
          setWorkbookActiveSheetIndex(workbook, getUserId(), id);
          setResized(spreadRef.current, lastCommandAppliedTime);
          overrideSheetDeleteEvent(spread);
          const loadingProgress = (100).toFixed(2);
          const loadingStatusItem = getStatusBarItem('loadingStatus');
          loadingStatusItem.updateText(`Loading: ${loadingProgress}%`);
          CustomLogger &&
            CustomLogger.pushLog(CustomLogger.operations.OPEN, {
              workpaperId: id,
              workpaperName,
              fileSize: workbookLength.toString(),
              versionId,
              duration: (Date.now() - operationStartTime).toString(),
            });
        },
        error => {
          setLoadWorkbookFailed(true);
          CustomLogger &&
            CustomLogger.pushLog(CustomLogger.operations.OPEN, {
              error: JSON.stringify(error),
              workpaperId: id,
              workpaperName,
              fileSize: workbookLength.toString(),
              versionId,
              duration: (Date.now() - operationStartTime).toString(),
            });
          throw error;
        },
        { jsonStream: true }
      );
      history.replace();
    } catch (error) {
      if (error && !isEmptyObject(error)) {
        CustomLogger &&
          CustomLogger.pushLog(CustomLogger.operations.OPEN, {
            error: JSON.stringify(error),
            workpaperId: id,
            versionId,
            duration: (Date.now() - operationStartTime).toString(),
          });

        if (error?.details) {
          switch (error?.details[0]?.code) {
            case 'STATIC_VERSION_ERROR':
              setLoadWorkbookFailed(true);
              break;
            case 'UNAUTHORIZED_WORKBOOK_RESOURCE':
              setLoadWorkbookNotFound(true);
              break;
            default:
              navigator.toHome();
              break;
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, versionId]);

  useEffect(() => {
    const setKeyValuePairs = async () => {
      keyValuePairs.current = await getAllKeyValues();
    };
    setKeyValuePairs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isWorkpaperLoaded) return;

    const processPendingCmds = async () => {
      if (isFeatureFlagEnabled(SJS_API)) {
        await processPendingCommandsWithSJS(id);
      } else {
        allCommandsProcessedAsync();
      }
    };

    const hanldeWorkpaperUnload = async () => {
      const versionId = versionIdRef.current;
      if (versionId || isLocked) return;

      await Promise.all([lockCleanup(), processPendingCmds(), syncCleanup()]);
      await verifyStaticVersion(id);
    };

    window.addEventListener('unload', hanldeWorkpaperUnload);

    return async () => {
      window.removeEventListener('unload', hanldeWorkpaperUnload);
      workbookManager.stopStatusRetry();

      await hanldeWorkpaperUnload();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocked, lockCleanup, isWorkpaperLoaded, syncCleanup]);

  useEffect(() => {
    publish({ message: MessageType.LoadWorkbookDataReferences, body: id, callback: loadWorkbookDataReferences });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (loadWorkbookNotFound) {
      history.push({ pathname: '/workpapers', state: { loadWorkbookNotFound } });
    }
  }, [loadWorkbookNotFound, history]);

  return {
    load,
    lockCleanup,
    setConfirmModalVisible,
    displayCircularReferenceAlert,
    lockInfo,
    loadWorkbookFailed,
    isConfirmModalVisible,
    spread: spreadRef.current,
  };
}
