import GC from '../../../../SpreadSheets';
import { useState, useRef } from 'react';
import {
  getWorkpaperDataReferences,
  resolveFormulaValues,
  updateReferences,
  deleteReferences,
  createWorkpaperDataReferences,
} from './apis';
import {
  formulaMatch,
  generateCellMetadata,
  generateOutputRequest,
  prepareBatchCommands,
  prepareBatchTagCommands,
  renderResolvedValues,
  setDataReferenceCellTags,
  updateDataReferenceQueue,
  updateInMemoryDataReferences,
  updateInMemoryDataReferencesQueue,
  createTempInMemoryDataReferences,
  getCellTag,
  resolveNewReferenceValue,
  resolveExistingReferenceValue,
  generateDirtyCellsCommands,
  cleanDirtyCellValue,
} from './dataReferenceHelper';
import { dataReferenceOverridePaint } from '../Spreadsheet/_spreadsheets/commands/customPaintOverride';
import useCellTracker from './useCellTracker';
import useReferenceScheduler from './useReferenceScheduler';
import { CELL_REVIEW, SOURCE_DATA_CONNECTION } from '../../../_shared/DataReference/ReferenceType';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { SJS_API } from '../../../../constants/featureFlags';

import { getDataFromLocalStorage } from '../../../_shared/storage';
import {
  CELL_REVIEW_ADDED,
  CELL_REVIEW_REMOVED,
  CELL_REVIEW_COMMAND_DELETE,
} from '../HistoryTracker/useHistoryTracker';
import { loadingString } from '../Spreadsheet/_spreadsheets/formulas';
export default function useDataReferenceManager({
  dataReferences,
  dataReferenceValues,
  isDragFillAction,
  isCopyPasteAction,
  enqueueCommands,
  tooltipManagerRef,
  spreadRef,
  dataReferenceHistoryTracker = null,
  dataReferenceWorkpaperId,
  dataReferenceWorkpaperVersionId,
  customPaintOverriden,
  dataLinksChannelsRef,
}) {
  const dataReferenceQueue = useRef([]);
  const dataReferenceCellReviewQueue = useRef([]);
  const dataReferencePositionUpdateQueue = useRef([]);
  const dataReferenceDeleteQueue = useRef([]);
  const dataReferenceCellTagUpdateQueue = useRef([]);
  const dataReferenceRecalcQueue = useRef([]);
  const isUndoDeleteCommand = useRef(false);
  const deleteCellReviewCommand = useRef([]);
  const lastCellReviewActionTimestamp = useRef(0);
  const { trackPosition, trackPastedReference, trackMetadataUpdate, clearReferenceCell } = useCellTracker(
    spreadRef,
    dataReferenceWorkpaperId,
    dataReferences,
    dataReferencePositionUpdateQueue,
    dataReferenceDeleteQueue,
    dataReferenceCellTagUpdateQueue,
    lastCellReviewActionTimestamp,
    deleteCellReviewCommand,
    dataReferenceHistoryTracker,
    dataLinksChannelsRef,
    processDataReferencePositionQueue,
    processDataReferencesDeleteQueue,
    enqueueManyDataReferences,
    processCellReviewQueue
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const processingInterval = 400;
  dataReferenceHistoryTracker.current.dataReferenceWorkpaperId = dataReferenceWorkpaperId;
  useReferenceScheduler(dataReferenceQueue, processDataReferenceQueue, processingInterval);
  useReferenceScheduler(dataReferenceRecalcQueue, processDataReferencesRecalculation, processingInterval);
  useReferenceScheduler(dataReferenceCellTagUpdateQueue, processCellTagUpdate, processingInterval);

  const SJS_ENABLED = isFeatureFlagEnabled(SJS_API);

  // ====================
  // Enqueue Process
  // ====================

  function enqueueManyDataReferences(references, isUndoDeleteCommandAction = false) {
    isUndoDeleteCommand.current = isUndoDeleteCommandAction;
    const spreadsheet = spreadRef.current.getActiveSheet();
    if (references.length > 0) {
      dataReferenceCellReviewQueue.current = [...dataReferenceCellReviewQueue.current, ...references];
      createTempInMemoryDataReferences(
        spreadsheet,
        dataReferences,
        dataReferenceCellReviewQueue,
        isUndoDeleteCommandAction
      );
      lastCellReviewActionTimestamp.current = Date.now();
    }

    if (references.length > 0) {
      createTempInMemoryDataReferences(spreadsheet, dataReferences, dataReferenceQueue, isUndoDeleteCommandAction);
    }
  }

  function enqueueDataReference(reference) {
    const spreadsheet = spreadRef.current.getActiveSheet();
    const { type, row, column, sheetName } = reference;
    if (type === CELL_REVIEW) {
      const calcReferenceExist = dataReferenceCellReviewQueue.current.filter(
        x => x.row === row && x.column === column && x.sheetName === sheetName
      );
      if (calcReferenceExist.length <= 0) {
        dataReferenceCellReviewQueue.current = [...dataReferenceCellReviewQueue.current, reference];
        createTempInMemoryDataReferences(spreadsheet, dataReferences, dataReferenceCellReviewQueue);
        lastCellReviewActionTimestamp.current = Date.now();
      }
    } else {
      const calcReferenceExist = dataReferenceQueue.current.filter(
        x => x.row === row && x.column === column && x.sheetName === sheetName
      );
      if (calcReferenceExist.length <= 0) {
        dataReferenceQueue.current = [...dataReferenceQueue.current, reference];
        createTempInMemoryDataReferences(spreadsheet, dataReferences, dataReferenceQueue);
      }
    }
  }

  function enqueueDataReferenceReCalc(reference) {
    const { row, column, sheetName } = reference;
    const recalcReferenceIndex = dataReferenceRecalcQueue.current.findIndex(
      x => x.row === row && x.column === column && x.sheetName === sheetName
    );
    if (recalcReferenceIndex !== -1) {
      dataReferenceRecalcQueue.current[recalcReferenceIndex] = reference;
    } else {
      dataReferenceRecalcQueue.current = [...dataReferenceRecalcQueue.current, reference];
    }
  }

  // ====================
  // References Validity
  // ====================

  function isFormulaMatch(reference) {
    return formulaMatch(dataReferences, reference);
  }

  function referenceExistInWorksheet(id, targetSheetName) {
    const reference = dataReferences.current.find(x => x.id === id && x.sheetName === targetSheetName);
    return !!reference;
  }

  function referenceExistInTargetCell(row, column, targetSheetName, type) {
    return dataReferences.current.find(
      x => x.row === row && x.column === column && x.sheetName === targetSheetName && x.type === type
    );
  }

  function referenceIsEnqueuedForRecalc(row, column, targetSheetName) {
    return dataReferenceRecalcQueue.current.some(
      x => x.row === row && x.column === column && x.sheetName === targetSheetName
    );
  }

  // ====================
  // References values resoltuion & renering
  // ====================

  async function repaintActiveSheet(references) {
    const activeSheet = spreadRef.current.getActiveSheet();
    const currentReferences = references?.length ? references : dataReferences.current;
    if (activeSheet) {
      const needsRepaint = currentReferences.filter(
        x => x.type === CELL_REVIEW && x.sheetName === activeSheet.name()
      ).length;

      if (needsRepaint) {
        repaint(activeSheet);
      }
    }
  }

  function renderCustomFormulaValues(cellTag, row, column, referenceType, sheet) {
    if (!cellTag?.references) {
      return resolveNewReferenceValue(dataReferenceValues, row, column, sheet);
    } else if (isDragFillAction.current.isActive) {
      const newValue = dataReferenceValues.current.find(x => {
        const { row: keyRow, column: keyColumn, sheetName: keySheetName } = JSON.parse(x.key);
        return keyRow === row && keyColumn === column && keySheetName === sheet;
      })?.value;

      const existingValue = dataReferences.current.find(
        x => x.row === row && x.column === column && x.sheetName === sheet
      )?.value;

      if (newValue === '') {
        return GC.Spread.CalcEngine.Errors.NotAvailable;
      } else if (newValue) {
        return resolveNewReferenceValue(dataReferenceValues, row, column, sheet);
      } else if (!newValue && existingValue) {
        return resolveExistingReferenceValue(dataReferences, cellTag, referenceType, sheet);
      } else {
        return null;
      }
    } else {
      const isCalc = dataReferenceQueue.current.some(
        x => x.row === row && x.column === column && x.sheetName === sheet
      );
      const isRecalc = dataReferenceRecalcQueue.current.some(
        x => x.row === row && x.column === column && x.sheetName === sheet
      );
      if (isCalc || isRecalc) {
        return resolveNewReferenceValue(dataReferenceValues, row, column, sheet);
      }

      return resolveExistingReferenceValue(dataReferences, cellTag, referenceType, sheet);
    }
  }

  async function resolveDataReferenceValues(sourceQueue) {
    let resolvedValuesResponseData = [];
    const outputRequest = await generateOutputRequest(sourceQueue, spreadRef.current.getActiveSheet().name());

    if (outputRequest.length > 0) {
      const resolvedValuesResponse = await resolveFormulaValues(dataReferenceWorkpaperId.current, outputRequest);
      if (resolvedValuesResponse.ok) {
        resolvedValuesResponseData = await resolvedValuesResponse.json();
        if (resolvedValuesResponseData?.length > 0) {
          resolvedValuesResponseData.forEach(resolvedValue => {
            const newParsedKey = JSON.parse(resolvedValue.key);
            if (newParsedKey) {
              const index = dataReferenceValues.current.findIndex(entry => {
                const existingValue = JSON.parse(entry.key);
                return (
                  existingValue &&
                  existingValue.row === newParsedKey.row &&
                  existingValue.column === newParsedKey.column &&
                  existingValue.sheetName === newParsedKey.sheetName
                );
              });

              if (index !== -1) {
                dataReferenceValues.current[index].value = resolvedValue.value;
              } else {
                dataReferenceValues.current.push(resolvedValue);
              }
            }
          });
        }
      } else {
        dataReferenceValues.current = outputRequest.map(request => ({
          ...request,
          value: GC.Spread.CalcEngine.Errors.NotAvailable,
        }));
      }
    }

    if (dataReferenceValues.current.length <= 0) {
      dataReferenceValues.current = outputRequest.map(request => ({
        ...request,
        value: GC.Spread.CalcEngine.Errors.NotAvailable,
      }));
    }
  }

  async function loadWorkbookDataReferences(workpaperId) {
    const { creatingWorkpaper } = JSON.parse(getDataFromLocalStorage(workpaperId) || '{}');

    if (!creatingWorkpaper) {
      const workbookDataReferences = await getWorkpaperDataReferences({
        workpaperId,
        filters: new Map([['includeDataLinksSources', true]]),
      });

      if (workbookDataReferences?.length) {
        let references = [...dataReferences.current, ...workbookDataReferences];
        if (dataReferenceWorkpaperVersionId?.current) {
          references = references.filter(x => x.type !== CELL_REVIEW);
        }
        dataReferences.current = [...new Map(references.map(reference => [reference.id, reference])).values()];
      }

      if (!customPaintOverriden.current) {
        dataReferenceOverridePaint(tooltipManagerRef.current, dataReferences);
        customPaintOverriden.current = true;
      }

      if (dataReferenceWorkpaperVersionId.current) {
        repaint(spreadRef.current);
      } else {
        await repaintActiveSheet();
      }
    }
  }

  function repaint(spread) {
    spread.suspendPaint();
    spread.resumePaint();
  }

  // ====================
  // References cell tags
  // ====================

  async function setReferenceCellTags() {
    const cellTagsToUpdate = await setDataReferenceCellTags(spreadRef.current.getActiveSheet(), dataReferences.current);
    await processBatchTagCommands(cellTagsToUpdate);
  }

  function getCellReferenceTag(sheet, row, column) {
    if (sheet) {
      const activeRow = sheet.getActiveRowIndex();
      const activeColumn = sheet.getActiveColumnIndex();
      const activeCellTag = getCellTag(sheet, activeRow, activeColumn);
      const contextCellTag = getCellTag(sheet, row, column);
      // The active cell tag is retrieved when cells are inserted from a cell containing a custom formula.
      // The active cell tag serves as the source tag and the context tag serves as the target tag.
      const cellTag = contextCellTag ? contextCellTag : activeCellTag;
      return cellTag;
    }
  }

  async function processCellTagUpdate() {
    if (dataReferenceCellTagUpdateQueue.current?.length > 0) {
      await processBatchTagCommands(dataReferenceCellTagUpdateQueue.current);
    }
  }

  async function processBatchTagCommands(spreadsheet, sourceReferences) {
    if (sourceReferences?.length > 0) {
      const commands = await prepareBatchTagCommands(spreadsheet, sourceReferences);
      enqueueCommands(commands);
    }
  }

  // ====================
  // References cell position tracker
  // ====================
  async function trackDataReferenceCellPositions({ command, sheet }) {
    trackPosition(command, sheet);
  }

  const handleCellReviewDeleteAction = (addToCellReviewHistory, spread, references) => {
    const deletedCellReviews = references
      ? references.current
      : dataReferences.current.filter(({ id }) => dataReferenceDeleteQueue.current.includes(id));
    addToCellReviewHistory(
      {
        cmd: references ? CELL_REVIEW_COMMAND_DELETE : CELL_REVIEW_REMOVED,
        data: deletedCellReviews,
      },
      spread
    );
    if (!references) {
      dataReferences.current = dataReferences.current.filter(
        ({ id }) => !dataReferenceDeleteQueue.current.includes(id)
      );
    }
  };

  // ====================
  // Base queue processors
  // ====================

  async function processDataReferenceQueue() {
    if (!dataReferenceQueue.current || dataReferenceQueue.current.length === 0) {
      return;
    }

    setIsProcessing(true);
    await resolveDataReferenceValues(dataReferenceQueue);
    await renderResolvedValues(spreadRef.current.getActiveSheet(), dataReferenceValues);
    const metadata = await generateCellMetadata(dataReferenceQueue, dataReferenceValues);
    isCopyPasteAction.current = { isActive: false, sameSheet: undefined, entireSheet: undefined };
    isDragFillAction.current = { isActive: false, direction: undefined };
    await updateInMemoryDataReferences(dataReferences, dataReferenceQueue, dataReferenceValues);
    const createDataReferences = await createWorkpaperDataReferences(dataReferenceWorkpaperId.current, metadata);
    if (createDataReferences) {
      dataReferences.current = createDataReferences;
    }
    await setReferenceCellTags();
    await processBatchCommands(dataReferenceQueue.current);
    await syncDataReferencesQueues();
    setIsProcessing(false);
  }

  async function processCellReviewQueue(spread, sheet, addToHistory, repaintOnLoad = true) {
    let alreadySyncData = false;
    if (!dataReferenceCellReviewQueue.current || dataReferenceCellReviewQueue.current.length === 0) {
      return { alreadySyncData: true };
    }

    setIsProcessing(true);
    const metadata = [];
    dataReferenceCellReviewQueue.current.forEach(({ id, value, row, column, sheetName, parameters, type }) => {
      metadata.push({
        parameters: typeof parameters === 'string' ? parameters : JSON.stringify(parameters),
        oldValue: value,
        newValue: value,
        row,
        column,
        sheetName,
        referenceType: type,
        referenceId: id,
      });
    });

    const createDataReferences = await createWorkpaperDataReferences(dataReferenceWorkpaperId.current, metadata);
    if (createDataReferences) {
      dataReferences.current = createDataReferences;
      if (repaintOnLoad) await repaintActiveSheet();
      alreadySyncData = true;
    }
    setIsProcessing(false);

    await setReferenceCellTags();
    const cellTagCommands = await prepareBatchTagCommands(sheet, dataReferenceCellReviewQueue.current);
    const dirtyCellsCommand = !isFeatureFlagEnabled(SJS_API)
      ? await generateDirtyCellsCommands(spreadRef, dataReferenceCellReviewQueue.current)
      : [];
    const commands = [...cellTagCommands, ...dirtyCellsCommand];
    enqueueCommands(commands);
    addToHistory &&
      addToHistory(
        {
          cmd: CELL_REVIEW_ADDED,
          data: dataReferenceCellReviewQueue.current,
        },
        spread,
        sheet
      );
    dataReferenceCellReviewQueue.current = [];
    isUndoDeleteCommand.current = false;

    return { alreadySyncData };
  }

  async function processDataReferencesRecalculation() {
    if (!dataReferenceRecalcQueue.current || dataReferenceRecalcQueue.current.length === 0) {
      return;
    }

    setIsProcessing(true);
    const activeSheet = spreadRef.current.getActiveSheet();
    const readyRecalcQueue = dataReferenceRecalcQueue.current.filter(
      ref => !(ref.type === SOURCE_DATA_CONNECTION && ref.filters?.some(filter => filter.value === loadingString))
    );
    await resolveDataReferenceValues({ current: readyRecalcQueue });
    await renderResolvedValues(activeSheet, dataReferenceValues);
    await updateInMemoryDataReferencesQueue(dataReferences, dataReferenceRecalcQueue, dataReferenceValues);

    const cellReviews = dataReferences.current.filter(
      ({ type, row, column, sheetName }) =>
        type === CELL_REVIEW &&
        dataReferenceRecalcQueue.current.some(y => y.row === row && y.column === column && y.sheetName === sheetName)
    );

    const reviewsToUpdate = cellReviews?.map(x => {
      x.value = cleanDirtyCellValue(
        dataReferenceRecalcQueue.current.find(
          y => y.row === x.row && y.column === x.column && y.sheetName === x.sheetName && y.type !== CELL_REVIEW
        )?.value
      );
      return x;
    });
    const referencesToUpdate = [...dataReferenceRecalcQueue.current, ...reviewsToUpdate];
    await updateReferences(dataReferenceWorkpaperId.current, referencesToUpdate);
    await processBatchCommands(dataReferenceRecalcQueue.current);
    await syncDataReferencesQueues(false);
    await processDataReferencePositionQueue();
    setIsProcessing(false);
  }

  function groupReferencesByIdReducer(grouped, ref) {
    if (!grouped[ref.workpaperId]) grouped[ref.workpaperId] = [];
    grouped[ref.workpaperId].push(ref);
    return grouped;
  }

  async function processDataReferencePositionQueue() {
    if (!dataReferencePositionUpdateQueue.current || dataReferencePositionUpdateQueue.current.length === 0) {
      return;
    }

    const references = dataReferencePositionUpdateQueue.current;
    const groupedById = references.reduce(groupReferencesByIdReducer, {});

    const updatePromises = [];
    for (const id in groupedById) {
      updatePromises.push(updateReferences(id, groupedById[id]));
    }

    const responses = await Promise.all(updatePromises);

    if (responses.every(r => r.ok)) {
      const sheet = spreadRef.current.getActiveSheet();
      const { alreadySyncData } = await processCellReviewQueue(spreadRef.current, sheet, null);
      await syncDataReferencesQueues(alreadySyncData);
      if (alreadySyncData) await repaintActiveSheet();
      const updatedPositionsReferences = dataReferencePositionUpdateQueue.current.filter(
        item => !references.includes(item)
      );

      dataReferencePositionUpdateQueue.current = updatedPositionsReferences;
    }
  }

  async function processDataReferencesDeleteQueue(
    workpaperIdParam = dataReferenceWorkpaperId.current,
    callback = null,
    isCellReview = false,
    spread = null,
    addToCellReviewHistory = null
  ) {
    if (dataReferenceDeleteQueue.current.length && !isUndoDeleteCommand.current) {
      if (isCellReview && spread && addToCellReviewHistory) {
        handleCellReviewDeleteAction(addToCellReviewHistory, spread, null);
      }
      if (
        dataReferenceHistoryTracker &&
        deleteCellReviewCommand.current.length &&
        dataReferenceHistoryTracker?.current?.addToHistory
      ) {
        handleCellReviewDeleteAction(
          dataReferenceHistoryTracker.current.addToHistory,
          spreadRef.current,
          deleteCellReviewCommand
        );
      }

      const response = await deleteReferences(workpaperIdParam, dataReferenceDeleteQueue.current);
      if (response.ok) {
        dataReferences.current = dataReferences.current.filter(
          ref => !dataReferenceDeleteQueue.current.includes(ref.id)
        );
        await repaintActiveSheet(deleteCellReviewCommand.current);
        await syncDataReferencesDeleteAction(workpaperIdParam, callback, true);
      }
    } else {
      await syncDataReferencesDeleteAction(workpaperIdParam, callback);
    }
  }

  async function processBatchCommands(metadata) {
    if (!dataReferenceValues.current || dataReferenceValues.current.length === 0) {
      return [];
    }

    const commands = [];
    const spreadsheet = spreadRef.current.getActiveSheet();

    if (!SJS_ENABLED) {
      const referenceCommands = await prepareBatchCommands(metadata, spreadsheet, dataReferenceValues.current);
      commands.push(...referenceCommands);
    }

    const tagsToUpdate = await setDataReferenceCellTags(spreadsheet, metadata);
    const tagCommands = await prepareBatchTagCommands(spreadsheet, tagsToUpdate);
    commands.push(...tagCommands);

    enqueueCommands(commands);
    return commands;
  }

  // ====================
  // Base queue synchronizers
  // ====================
  async function syncDataReferencesDeleteAction(workpaperIdParam, callback = null, alreadySyncData = false) {
    if (!alreadySyncData) await loadWorkbookDataReferences(workpaperIdParam);
    dataReferenceDeleteQueue.current = [];
    deleteCellReviewCommand.current = [];
    isUndoDeleteCommand.current = false;
    callback && callback();
  }

  async function syncDataReferencesQueues(alreadySyncData = true) {
    const spreadsheet = spreadRef.current.getActiveSheet();
    if (!alreadySyncData) await loadWorkbookDataReferences(dataReferenceWorkpaperId.current);
    await setReferenceCellTags();
    await updateDataReferenceQueue(spreadsheet, dataReferenceQueue, dataReferenceValues);
    await updateDataReferenceQueue(spreadsheet, dataReferenceRecalcQueue, dataReferenceValues);
  }

  return {
    clearReferenceCell,
    isProcessing,
    dataReferences,
    dataReferenceRecalcQueue,
    dataReferenceValues,
    dataReferenceDeleteQueue,
    dataReferenceQueue,
    enqueueDataReference,
    enqueueManyDataReferences,
    enqueueDataReferenceReCalc,
    getCellReferenceTag,
    loadWorkbookDataReferences,
    processBatchCommands,
    renderResolvedValues,
    renderCustomFormulaValues,
    setReferenceCellTags,
    trackDataReferenceCellPositions,
    trackPastedReference,
    trackMetadataUpdate,
    updateDataReferenceQueue,
    isDragFillAction,
    referenceExistInWorksheet,
    referenceExistInTargetCell,
    referenceIsEnqueuedForRecalc,
    isFormulaMatch,
    processCellReviewQueue,
    processDataReferencesDeleteQueue,
    resolveDataReferenceValues,
  };
}
