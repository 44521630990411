import { useState } from 'react';

const mockClients = [
  {
    name: 'Firm 1',
    clientNumber: '4456',
    userAccess: 'RESTRICTED',
    id: '0190e141-c915-796f-a495-cc44b5c9cccc',
  },
  {
    name: 'Firm 2',
    clientNumber: '4457',
    userAccess: 'RESTRICTED',
    id: '0190e141-c915-796f-a495-cc44b5c9cccd',
  },
  {
    name: 'Firm 3',
    clientNumber: '4458',
    userAccess: 'RESTRICTED',
    id: '0190e141-c915-796f-a495-cc44b5c9ccce',
  },
  {
    name: 'Firm 4',
    clientNumber: '4459',
    userAccess: 'RESTRICTED',
    id: '0190e141-c915-796f-a495-cc44b5c9cccf',
  },
  {
    name: 'Firm 5',
    clientNumber: '4460',
    userAccess: 'RESTRICTED',
    id: '0190e141-c915-796f-a495-cc44b5c9cccg',
  },
];

export function useUserProfile() {
  const [selectedClient, setSelectedClient] = useState(mockClients[0]);
  const [clients] = useState(mockClients);

  const isAdmin = () => {
    const tokenInfo = localStorage.getItem('token-info');
    if (!tokenInfo) return false;

    const userInfo = JSON.parse(tokenInfo);
    const isUserAdmin = userInfo.preferences?.some(
      pref => pref.scope === 'cpoc' && pref.type === 'role' && pref.value === 'admin'
    );

    return isUserAdmin;
  };

  return {
    clients,
    selectedClient,
    setSelectedClient,
    isAdmin,
  };
}
