import * as fetch from '../../../_shared/fetch';
import {
  spreadsheetServiceHostName,
  dataFlowServiceHostName,
  workpapersSpreadsheetModelHostName,
} from '../../../../configs/params';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { SJS_API } from '../../../../constants/featureFlags';

async function createWorkpaperSpreadsheet(workpaperId) {
  const path = `${spreadsheetServiceHostName}/spreadsheet/files/create`;
  return await fetch.post(path, {
    body: JSON.stringify({
      workpaperId,
    }),
  });
}

async function createWorkpaperSpreadsheetModel(workpaperId) {
  console.log('*****createWorkpaperSpreadsheetModel*****');
  const path = `${workpapersSpreadsheetModelHostName}/file/create`;
  return await fetch.post(path, {
    body: JSON.stringify({
      workpaperId,
    }),
  });
}

export async function createWorkpaper(workpaperId) {
  const createWorkpaperRequest = isFeatureFlagEnabled(SJS_API)
    ? createWorkpaperSpreadsheetModel
    : createWorkpaperSpreadsheet;
  const response = await createWorkpaperRequest(workpaperId);
  const data = await response.json();
  console.log('createWorkpaper->response->', response.status);
  return data.id;
}

export async function createDataFlow() {
  const response = await fetch.post(`${dataFlowServiceHostName}/api/v1/data-flows`);

  const data = await response.json();

  return data.id;
}
